<template>
  <div class="payplan-dialog">
    <div class="erp-form__header header-bar__sticky op-16">
      <div class="erp-form__title">{{ title }}</div>
      <div class="erp-form-bar">
        <el-button class="erp-primary__btn" v-if="formType === 4 || formType === 5" size="small" type="primary" @click="submit(formType)">确认提交</el-button>
        <el-button class="erp-primary__btn" v-if="formType === 7" size="small" type="primary" @click="submit(formType)">提交</el-button>
        <div class="erp-default__btn" @click="back">返回</div>
      </div>
    </div>
    <div class="paypalan__warp op-16">
      <el-row :gutter="30" type="flex" class="flex-warp">
        <el-col :span="12">
          <erp-view label="供应商名称" :value="initData.supplierName"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="供应商合同号" :value="initData.supplierContractNo"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="国家" :value="initData.countryDesc"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="厂号" :value="initData.plantNo"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="供应商预付比例" :value="supplierPaymentRatio"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="预计付尾款金额" :value="expectPayAmountAndTrandency"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="实际货值" :value="actualAmountAndTrandency"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="实际付预付款金额" :value="actualPayRatioAmountAndTrandency"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="启运日" :value="initData.shippingStartDate"></erp-view>
        </el-col>
        <el-col :span="12">
          <erp-view label="到港日" :value="initData.shippingEndDate"></erp-view>
        </el-col>
      </el-row>
    </div>
    <div class="erp-form__body">
      <div class="erp-form__title f16 payplan">客户</div>
      <el-table :data="customList" border class="erp-table__list">
        <el-table-column label="客户名称" prop="customerName" class-name="no-pd" align="center" show-overflow-tooltip width="125">
          <template #default="scope">
            {{ filterName(scope.row.customerName) }}
          </template>
        </el-table-column>
        <el-table-column label="销售合同号" prop="businessContractNo" class-name="no-pd" align="center" show-overflow-tooltip width="110">
          <template #default="scope">
            {{ filterName(scope.row.businessContractNo) }}
          </template>
        </el-table-column>
        <el-table-column label="抬头" prop="consigneeShortName" class-name="no-pd" align="center" show-overflow-tooltip width="100">
          <template #default="scope">
            {{ filterName(scope.row.consigneeShortName) }}
          </template>
        </el-table-column>
        <el-table-column label="签约日期" prop="signDate" class-name="no-pd" align="center" show-overflow-tooltip width="104">
          <template #default="scope">
            {{ filterName(scope.row.signDate) }}
          </template>
        </el-table-column>
        <el-table-column label="合同总货值" prop="contractAmount" class-name="no-pd" align="center" show-overflow-tooltip width="100">
          <template v-slot:header>
            <span>合同总货值({{ tradeCurrencyDesc }})</span>
          </template>
          <template #default="scope">
            {{ filterName(scope.row.contractAmountDesc) }}
          </template>
        </el-table-column>
        <el-table-column label="保证金比例" prop="depositRate" class-name="no-pd" align="center" show-overflow-tooltip width="70">
          <template #default="scope">
            {{ filterRate(scope.row.depositRate) }}
          </template>
        </el-table-column>
        <el-table-column label="客户指定汇率" prop="prescribedRate" class-name="no-pd" align="center" show-overflow-tooltip width="80">
          <template #default="scope">
            {{ filterName(scope.row.prescribedRate) }}
          </template>
        </el-table-column>
        <el-table-column label="应收客户保证金" prop="expectDepositRateAmount" class-name="no-pd" align="center" show-overflow-tooltip width="90">
          <template #default="scope">
            {{ filterName(scope.row.expectDepositRateAmountDesc) }}
          </template>
        </el-table-column>
        <el-table-column label="实际收到保证金" prop="actualDepositRateAmount" class-name="no-pd" align="center" show-overflow-tooltip width="90">
          <template #default="scope">
            {{ filterName(scope.row.actualDepositRateAmountDesc) }}
          </template>
        </el-table-column>
        <el-table-column label="收到保证金日期" prop="depositDate" class-name="no-pd" align="center" show-overflow-tooltip>
          <template #default="scope">
            {{ filterName(scope.row.depositDate) }}
          </template>
        </el-table-column>
      </el-table>
      <div class="flex-bar">
        <div class="erp-form__title f16">证件</div>
        <div class="batch-download" @click="batchDownload">
          <div class="batch-download__inner">
            <img src="../../../../../assets/images/icon-batch-label.png" alt="" />
            下载证件
          </div>
        </div>
      </div>

      <el-table :data="attachmentArrays" border class="egrid erp-table__list erp-view__table" :span-method="spanMethods">
        <el-table-column label="证件类型" prop="attachmentTypeDisplay" align="center" width="180" class-name="line-cell">
          <template v-slot="scope">
            <div class="line-cell">{{ scope.row.type }}</div>
          </template>
        </el-table-column>
        <el-table-column label="文件名" prop="name" align="center">
          <template v-slot="scope" class="custom">
            <div v-if="scope.row.attachmentFiles && scope.row.attachmentFiles.length > 0" class="flex-link">
              <div
                v-for="(item, index) in scope.row.attachmentFiles"
                :key="index"
                @click="showFiles(item.name, item.url)"
                class="show-link no-line"
                :title="item.name.length >= 17 ? item.name : ''"
              >
                {{ subUrl(item.name) }}
              </div>
            </div>
            <div v-else class="no-upload">未上传</div>
          </template>
        </el-table-column>
      </el-table>

      <div class="erp-form__title f16 payplan">申请备注</div>
      <!-- 3查看  31 查看审核付款  4提交申请 5重新提交  6取消  7审核   8录入信息 -->
      <div class="input-desc op-16" v-if="formType === 4 || formType === 5">
        <el-input :maxlength="100" v-model="formData.remark" clearable placeholder="请输入申请备注" type="textarea" class="mali-full__inputcom h88"></el-input>
      </div>
      <!-- 3查看  31 查看审核付款  4提交申请 5重新提交  6取消  7审核   8录入信息 -->
      <div class="view-input" v-if="formType === 3 || formType === 31 || formType === 7">
        <div class="input-content">
          {{ filterName(initData.applyDesc) }}
        </div>
        <div class="input-detail">
          <span>申请人：{{ filterName(initData.applyOperator) }}</span>
          <span>申请时间：{{ filterName(initData.applyTime) }}</span>
          <span v-if="formType === 3 && initData.noticeCancelOperator">取消人：{{ filterName(initData.noticeCancelOperator) }}</span>
          <span v-if="formType === 3 && initData.noticeCancelOperator">取消时间：{{ filterName(initData.noticeCancelTime) }}</span>
        </div>
      </div>
      <div v-if="formType === 7">
        <div class="erp-form__title f16 payplan">审核操作</div>
        <div class="payplanAudit-form-wrap">
          <div class="check-box">
            <el-radio-group v-model="formData.auditStatus">
              <el-radio :label="3">审核通过</el-radio>
              <el-radio :label="2">审核不通过</el-radio>
            </el-radio-group>
          </div>
          <div class="input-form-label">审核说明：</div>
          <div class="input-box">
            <el-input
              :maxlength="100"
              v-model="formData.remark"
              clearable
              placeholder="请输入审核说明"
              type="textarea"
              class="mali-full__inputcom h88"
            ></el-input>
          </div>
        </div>
      </div>
      <div v-if="formType === 31 && initData.auditOperator">
        <div class="erp-form__title f16 payplan">审核操作</div>
        <div class="view-input">
          <div class="pass">是否通过：{{ filterName(initData.auditStatusDesc) }}</div>
          <div class="input-content">审核说明：{{ filterName(initData.auditDesc) }}</div>
          <div class="input-detail">
            <span class>审核人：{{ filterName(initData.auditOperator) }}</span>
            <span class>审核时间：{{ filterName(initData.auditTime) }}</span>
          </div>
        </div>
      </div>
      <div v-if="formType === 31 && initData.cancelOperator">
        <div class="erp-form__title f16 payplan">取消信息</div>
        <div class="view-input">
          <div class="input-content">取消说明：{{ filterName(initData.cancelDesc) }}</div>
          <div class="input-detail">
            <span class>取消人：{{ filterName(initData.cancelOperator) }}</span>
            <span class>取消时间：{{ filterName(initData.cancelTime) }}</span>
          </div>
        </div>
      </div>
      <div v-if="formType === 31 && initData.expectPayDate">
        <div class="erp-form__title f16 payplan">报单信息</div>
        <div class="view-input">
          <div class="pass">预计付款日期：{{ filterName(initData.expectPayDate) }}</div>
          <div class="input-content">备注：{{ filterName(initData.paymentDesc) }}</div>
        </div>
      </div>
      <div v-if="formType === 31 && initData.actualApplyPayDate">
        <div class="erp-form__title f16 payplan">实际付款信息</div>
        <div class="view-input">
          <div class="pass">实际付尾款日期：{{ filterName(initData.actualApplyPayDate) }}</div>
          <div class="pass">实际付尾款金额：{{ filterName(actualPayTailAmountAndTrandency) }}</div>
        </div>
      </div>
    </div>
    <div></div>
  </div>
</template>
<script>
import { httpGet, httpPost } from '@/api';
import { errorMessage, handleBatchDownload } from '@/utils/publicMethods';

export default {
  components: {},
  props: {
    formType: {
      // 表单操作类型 // formType 3 查看 31 查看   4提交申请 5重新提交  6取消  7审核   8录入信息
      type: [String, Number],
      default: '',
    },
    title: {
      type: [String],
      default: '',
    },
    rowData: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      formData: {
        remark: '',
        auditStatus: null,
      },
      initData: {
        supplierName: '',
        supplierContractNo: '',
        countryDesc: '',
        expectApplyPayAmount: '',
        expectApplyPayAmountDesc: '',
        actualAmountDesc: '',
        plantNo: '',
        supplierPaymentRatio: '',
        applyDesc: '',
        id: '',
        version: 0,
        auditDesc: '',
        auditStatus: null,
        actualPayAmountDesc: '',
        expectPayDate: '',
        paymentDesc: '',
        actualApplyPayDate: '',
        actualPayTailAmountDesc: '',
        customerName: '',
        businessContractNo: '',
        consigneeShortName: '',
        signDate: '',
        contractAmount: '',
        contractAmountDesc: '',
        actualAmount: '',
        depositRate: '',
        expectDepositRateAmount: '',
        expectDepositRateAmountDesc: '',
        actualDepositRateAmount: '',
        actualDepositRateAmountDesc: '',
        depositDate: '',
        prescribedRate: '',
        tradeCurrencyDesc: '',
        remark: '',
        cancelMan: '',
        cancelTime: '',
      },
      customList: [],
      attachmentArrays: [],
      colNames: ['stage'],
      colArrays: {},
      isAjaxLoading: false,
    };
  },
  computed: {
    expectPayAmountAndTrandency() {
      let str = '';
      if (this.initData.expectPayAmountDesc !== null && this.initData.expectPayAmountDesc !== undefined && this.initData.expectPayAmountDesc !== '') {
        str += this.initData.expectPayAmountDesc;
        if (this.initData.tradeCurrencyDesc) {
          str += '(' + this.initData.tradeCurrencyDesc + ')';
        }
      }
      return str;
    },
    actualAmountAndTrandency() {
      let str = '';
      if (this.initData.actualAmountDesc !== null && this.initData.actualAmountDesc !== undefined && this.initData.actualAmountDesc !== '') {
        str += this.initData.actualAmountDesc;
        if (this.initData.tradeCurrencyDesc) {
          str += '(' + this.initData.tradeCurrencyDesc + ')';
        }
      }
      return str;
    },
    actualPayRatioAmountAndTrandency() {
      let str = '';
      if (
        this.initData.actualPayRatioAmountDesc !== null &&
        this.initData.actualPayRatioAmountDesc !== undefined &&
        this.initData.actualPayRatioAmountDesc !== ''
      ) {
        str += this.initData.actualPayRatioAmountDesc;
        if (this.initData.tradeCurrencyDesc) {
          str += '(' + this.initData.tradeCurrencyDesc + ')';
        }
      }
      return str;
    },
    actualPayTailAmountAndTrandency() {
      let str = '';
      if (
        this.initData.actualPayTailAmountDesc !== null &&
        this.initData.actualPayTailAmountDesc !== undefined &&
        this.initData.actualPayTailAmountDesc !== ''
      ) {
        str += this.initData.actualPayTailAmountDesc;
        if (this.initData.tradeCurrencyDesc) {
          str += '(' + this.initData.tradeCurrencyDesc + ')';
        }
      }
      return str;
    },
    supplierPaymentRatio() {
      let res = this.initData.supplierPaymentRatio;
      if (res) {
        res += '%';
      }
      return res;
    },
  },
  created() {
    this.getInit(this.rowData.id);
  },
  methods: {
    // 批量下载证件栏
    batchDownload() {
      let zipName = this.initData.businessContractNo + '尾款证件';
      let selectImgList = [];
      for (let i = 3; i <= 14; i++) {
        let name = 'attachment' + i;
        let attachment = this.initData[name];
        if (attachment && attachment.length > 0) {
          attachment.forEach((v) => {
            if (v.url && v.url !== '') {
              let selectImg = {
                name: v.name,
                url: v.url,
              };
              selectImgList.push(selectImg);
            }
          });
        }
      }
      if (selectImgList.length <= 0) {
        errorMessage('没有要下载的文件');
      } else {
        handleBatchDownload(selectImgList, zipName);
      }
    },
    subUrl(val) {
      const suffix = val.substr(val.lastIndexOf('.'));
      let str = val.replace(suffix, '');
      if (str.length <= 20) {
        return val;
      } else {
        str = str.substr(0, 20) + '..' + suffix;
        return str;
      }
    },
    filterRate(val) {
      if (val) {
        return val + '%';
      } else {
        return '-';
      }
    },
    back() {
      this.$emit('cancel', true);
    },
    async getInit(id) {
      // formType 3 查看 31 查看   4提交申请 5重新提交  6取消  7审核  8录入信息
      if (!id) return;
      let url = '';
      // 7审核
      if ([31, 7].includes(this.formType)) {
        url = `malicrm/apply/pay/getApplyPaymentDetail/${id}`;
      }
      // 预付提醒付款
      if ([3, 4, 5].includes(this.formType)) {
        url = `malicrm/apply/pay/getApplyPaymentNoticeDetail/${id}`;
      }

      const res = await httpGet(url);
      if (res.code === 200) {
        this.initData = res.result;
        this.intAttachmentArrays(res.result);
        this.initCustomerArrays();
        this.setColArrays(this.colNames);
      }
    },
    // 初始化显示
    initCustomerArrays() {
      const {
        customerName,
        contractAmount,
        actualAmount,
        actualAmountDesc,
        contractAmountDesc,
        businessContractNo,
        consigneeShortName,
        signDate,
        totalAmount,
        depositRate,
        prescribedRate,
        expectDepositRateAmountDesc,
        actualDepositRateAmount,
        actualDepositRateAmountDesc,
        depositDate,
      } = this.initData;
      this.customList = [];
      this.customList.push({
        customerName,
        contractAmount,
        contractAmountDesc,
        actualAmount,
        actualAmountDesc,
        businessContractNo,
        consigneeShortName,
        signDate,
        totalAmount,
        depositRate,
        prescribedRate,
        expectDepositRateAmountDesc,
        actualDepositRateAmount,
        actualDepositRateAmountDesc,
        depositDate,
      });
    },
    setColArrays(props) {
      const dot = {};
      props.forEach((v) => {
        dot[v] = 0;
      });
      this.attachmentArrays.forEach((v, i) => {
        if (i === 0) {
          props.forEach((item) => {
            this.colArrays[item] = [];
            this.colArrays[item].push(1);
          });
        } else {
          props.forEach((prop) => {
            if (v[prop] === this.attachmentArrays[i - 1][prop] && v.stage === this.attachmentArrays[i - 1].stage) {
              // 必须是同柜的
              this.colArrays[prop][dot[prop]] += 1;
              this.colArrays[prop].push(0);
            } else {
              this.colArrays[prop].push(1);
              dot[prop] = i;
            }
          });
        }
      });
    },
    intAttachmentArrays(data) {
      const {
        attachment4 = [],
        attachment5 = [],
        attachment6 = [],
        attachment7 = [],
        attachment8 = [],
        attachment9 = [],
        attachment10 = [],
        attachment11 = [],
        attachment12 = [],
        attachment3 = [],
        attachment13 = [], // 正本-正本SC
        attachment14 = [], // 正本-全套正本文件
      } = data;
      const arrays = [
        {
          stage: '副本',
          type: '副本文件',
          required: true,
          attachmentFiles: attachment3 || null,
        },
        {
          stage: '正本',
          type: '卫生证',
          required: true,
          attachmentFiles: attachment4 || null,
        },
        {
          stage: '正本',
          type: '产地证',
          required: true,
          attachmentFiles: attachment5 || null,
        },
        {
          stage: '正本',
          type: '提单',
          required: true,
          attachmentFiles: attachment6 || null,
        },
        {
          stage: '正本',
          type: '装箱单',
          required: true,
          attachmentFiles: attachment7 || null,
        },
        {
          stage: '正本',
          type: '发票',
          required: true,
          attachmentFiles: attachment8 || null,
        },
        {
          stage: '正本',
          type: '正本SC',
          required: true,
          attachmentFiles: attachment9 || null,
        },
        {
          stage: '正本',
          type: '批次信息',
          required: true,
          attachmentFiles: attachment10 || null,
        },
        {
          stage: '正本',
          type: '清真证',
          required: false,
          attachmentFiles: attachment11 || null,
        },

        {
          stage: '正本',
          type: '无木证明',
          required: false,
          attachmentFiles: attachment12 || null,
        },
        {
          stage: '正本',
          type: '保险单',
          required: false,
          attachmentFiles: attachment13 || null,
        },
        {
          stage: '正本',
          type: '全套正本文件',
          required: false,
          attachmentFiles: attachment14 || null,
        },
      ];
      this.attachmentArrays = arrays;
    },
    spanMethods({ column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        const prop = column.property; // 动态字段名
        let params = {
          rowspan: 1,
          colspan: 1,
        };
        if (this.colNames && this.colNames.length > 0) {
          this.colNames.forEach((v) => {
            if (v === prop) {
              const _row = this.colArrays[v][rowIndex];
              const _col = _row > 0 ? 1 : 0;
              params = {
                rowspan: _row,
                colspan: _col,
              };
            }
          });
        }
        return params;
      }
    },
    showFiles(name, url) {
      this.$showFiles(name, url);
    },
    clickTag(url) {
      if (url && url !== '') {
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.click();
      }
    },
    /**
     * 提交事件
     */
    submit() {
      this.saveSubmitForm();
    },
    async saveSubmitForm() {
      // formType 3 查看 31 查看   4提交申请 5重新提交  6取消  7审核   8录入信息
      let params = {};
      const { applyType } = this.rowData;
      const { auditStatus, remark } = this.formData;
      const { version, id } = this.initData;
      let url = '';
      // applyType 申请付款类型 1.预付款 2.尾款
      params = { version, applyType, id, remark };
      // 4提交申请
      if (this.formType === 4) {
        url = '/malicrm/apply/pay/commitApply';
        params.applyPayAmount = this.initData.expectPayAmount;
      }
      // 5重新提交
      if (this.formType === 5) {
        url = '/malicrm/apply/pay/reCommitApply';
        params.applyPayAmount = this.initData.expectPayAmount;
      }
      if (this.formType === 7 && !this.formData.auditStatus) {
        this.errorTip('请进行审核操作');
        return;
      }
      if (this.formType === 7 && this.formData.auditStatus === 2 && !this.formData.remark) {
        this.errorTip('审核不通过请说明原因');
        return;
      }
      // 审核 尾款
      if (this.formType === 7) {
        params.auditStatus = auditStatus;
        params.applyPayAmount = this.initData.applyPayAmount;
        url = '/malicrm/apply/pay/auditApply';
      }
      if (this.isAjaxLoading) {
        return;
      }
      this.isAjaxLoading = true;
      const res = await httpPost(url, params);
      this.isAjaxLoading = false;
      if (res.code === 200) {
        this.successTip('提交成功');
        this.$emit('success', true); // 通知列表页面关闭弹窗 true 刷新列表 false不刷新列表
      } else {
        this.errorTip(res.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.paypalan__warp {
  margin-left: 10px;
  margin-right: 10px;
}
.payplan-dialog {
  .erp-form__title.payplan {
    margin-left: 16px;
    margin-bottom: 12px;
    margin-top: 30px;
  }
}
.flex-link {
  display: flex;
  flex-wrap: wrap;
  padding: 7px 0 5px 0;
}
.show-link {
  color: #2878ff;
  margin-right: 30px;
  cursor: pointer;
  margin-bottom: 3px;
}
.goods-tag {
  cursor: pointer;
}
.view-input {
  margin-left: 28px;
  margin-right: 28px;
  .pass {
    font-size: 14px;
    color: rgba(48, 49, 51, 1);
    margin-top: 25px;
    margin-bottom: 20px;
  }
  .input-content {
    font-size: 14px;
    color: rgba(48, 49, 51, 1);
    line-height: 24px;
    border-bottom: 1px solid rgba(220, 223, 230, 1);
    padding-bottom: 6px;
  }
  .input-detail {
    text-align: right;
    margin-top: 16px;
    margin-bottom: 26px;
    span {
      color: rgba(96, 98, 102, 1);
      font-size: 14px;
      margin-left: 20px;
    }
  }
}
.req::after {
  content: '*';
  color: #ff2619;
  margin-left: 3px;
}
</style>
